<template>
  <ion-toolbar class="pl-1">
    <card-change-company
      :selectedCompany="selectedCompany"
      :isHideSome="!isOnScrollTop"
      @refreshData="$emit('reRenderToolbar')"
    />
    <ion-grid>
      <ion-row class="ion-align-items-center">
        <ion-col size="9" class="search-product new-search">
          <ion-input
            class="search"
            :placeholder="$t('search_all_products')"
            :readonly="true"
            @click="openSearchPage"
          />
        </ion-col>
        <ion-col size="3" class="header-right">
          <ion-row class="ion-justify-content-around align-center">
            <ion-icon class="icon-item" :icon="filterOutline" @click="showModalFilter" />
            <notification-badge />
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</template>
<script>
import Sort from '@/components/sort';
import { getItemByKey, useUpdateFilter } from '@/components/sort/composable';
import { sortBy, sortType } from '@/components/sort/data';
import { TYPE_SEARCH, defaultSize } from '@/modules/b2b/constants';
import { ACTIONS } from '@/modules/b2b/store/product/actions';
import { MUTATIONS } from '@/modules/b2b/store/product/mutations';
import CardChangeCompany from '@/modules/shared/components/CardChangeCompany.vue';
import NotificationBadge from '@/modules/shared/components/notifications/NotificationBadge.vue';
import {
  B2B_PRODUCT_STORE,
  DEFAULT_SORT_ALL_KEY,
  GETTER,
  MODULES_FILTER,
  OFFSET_LIST_DEFAULT
} from '@/modules/shared/constants/';
import { useModal } from '@/modules/shared/utils/';
import Clevertap from '@/services/shared/helper/clevertap';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { modalController, popoverController } from '@ionic/vue';
import {
  businessOutline,
  closeOutline,
  filterOutline,
  notificationsOutline,
  storefrontOutline
} from 'ionicons/icons';
import debounce from 'lodash.debounce';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { createNamespacedHelpers } from 'vuex';
import ConnectedVendor from './ConnectedVendor.vue';
import FilterPopover from './FilterPopover.vue';

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('b2b/product');
const { mapGetters: mapGettersFilter } = createNamespacedHelpers(MODULES_FILTER);

export default defineComponent({
  components: {
    NotificationBadge,
    CardChangeCompany
  },
  emits: [
    'resetLoadAllProduct',
    'resetSearch',
    'resetRef',
    'clearPrevFilter',
    'setIsHomePageValue',
    'reRenderToolbar'
  ],
  inject: ['$storage'],
  props: {
    isLoadAllProduct: {
      type: Boolean,
      default: false
    },
    isResetSearch: {
      type: Object,
      default: () => {}
    },
    selectedTab: {
      type: String,
      default: ``
    },
    currentFilter: {
      type: String,
      default: ``
    },
    isExploreProduct: {
      type: Boolean,
      default: false
    },
    selectedCompany: {
      type: Object,
      default: () => {}
    },
    isOnScrollTop: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const user = ref(null);
    const isOpenRef = ref(false);
    const event = ref();
    const setOpen = (state, event) => {
      event.value = event;
      isOpenRef.value = state;
    };
    const route = useRoute();
    const { createModal, dismissModal } = useModal(Sort, 'custom-modal');
    const clearPreviousFilter = () => {
      emit('clearPrevFilter');
    };
    const showModalFilter = () => {
      Clevertap.onUserOpenFilterProduct();
      createModal({
        storeAddress: B2B_PRODUCT_STORE,
        tab: props.selectedTab,
        closeModal: dismissModal,
        currentFilter: props.currentFilter,
        clearPrevFilter: clearPreviousFilter
      });
    };
    const { onSetDefaultSort } = useUpdateFilter();
    const isOpenBranchSelection = ref(false);

    return {
      filterOutline,
      businessOutline,
      storefrontOutline,
      notificationsOutline,
      closeOutline,
      showModalFilter,
      isOpenBranchSelection,
      setOpen,
      isOpenRef,
      event,
      user,
      route,
      Keyboard,
      onSetDefaultSort,
      customer: ref(null),
      searchdata: ref(''),
      itemSort: ref(null),
      defTab: ref({
        cate: 'history',
        checkCate: 'history-'
      })
    };
  },
  computed: {
    ...mapGetters(['search', 'type']),
    ...mapGettersFilter([GETTER.GET_FILTER])
  },
  async mounted() {
    this.user = await this.$storage.getUser();
  },
  watch: {
    isLoadAllProduct() {
      this.fetchAllProduct();
      this.$emit('resetLoadAllProduct');
    },
    isResetSearch(newValue) {
      this.search.searchQueries = '';
      this.chooseCate(newValue.cate);
    },
    [GETTER.GET_FILTER]: {
      handler(newKey, oldKey) {
        this.itemSort = getItemByKey({ categoryName: this.search.mainCategoryId, key: newKey || oldKey });
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions([ACTIONS.UPDATE_SEARCH]),
    ...mapMutations([MUTATIONS.RESET_CATEGORY]),
    handleEnter() {
      if (Capacitor.isNativePlatform()) {
        this.Keyboard.hide();
      }
    },
    onUpdateSearch() {
      this.search.searchQueries = this.searchdata;
      this.onSearch(this.search);
    },
    async onSearch() {
      Clevertap.onUserSearchProduct(this.search.searchQueries);
      this[MUTATIONS.RESET_CATEGORY]();
      await this[ACTIONS.UPDATE_SEARCH]({
        search: {
          ...this.search,
          offset: OFFSET_LIST_DEFAULT,
          sortBy: this.isExploreProduct ? sortBy.popular : this.itemSort?.sortBy,
          sortType: this.isExploreProduct ? sortType.desc : this.itemSort?.sortType,
          mainCategoryId: this.search.mainCategoryId
        },
        type: TYPE_SEARCH.INPUT
      });
    },

    async clearSearch() {
      this.searchdata = '';
      this.onUpdateSearch();
      this.handleEnter();
    },

    chooseCate: debounce(async function (id) {
      this.itemSort = getItemByKey({ categoryName: id, key: this.GET_FILTER });
      this.onSetDefaultSort(id);
      const searchParams = {
        mainCategoryId: id + '',
        limit: defaultSize,
        searchQueries: '',
        offset: OFFSET_LIST_DEFAULT,
        sortBy: this.isExploreProduct ? sortBy.popular : this.itemSort?.sortBy,
        sortType: this.isExploreProduct ? sortType.desc : this.itemSort?.sortType,
        isSample: false
      };
      await this[ACTIONS.UPDATE_SEARCH]({ search: searchParams, type: TYPE_SEARCH.INPUT });
    }, 0),

    fetchAllProduct: debounce(async function (search) {
      this[MUTATIONS.RESET_CATEGORY]();
      this.itemSort = getItemByKey({ categoryName: 'all', key: DEFAULT_SORT_ALL_KEY });
      this.onSetDefaultSort('all');
      await this[ACTIONS.UPDATE_SEARCH]({
        search: {
          ...search,
          offset: OFFSET_LIST_DEFAULT,
          sortBy: this.itemSort?.sortBy,
          sortType: this.itemSort?.sortType,
          mainCategoryId: 'all'
        },
        type: TYPE_SEARCH.INPUT
      });
      if (this.search.searchQueries !== this.searchdata) {
        this.search.searchQueries = this.searchdata;
        this.onSearch(this.search);
      }
    }),

    async showPopover(event) {
      const popover = await popoverController.create({
        event,
        component: FilterPopover,
        cssClass: 'my-popover', // optional,
        showBackdrop: true,
        componentProps: {
          closePopover: async () => {
            await popover.dismiss({});
          }
        }
      });
      return await popover.present();
    },

    async setOpenModalBranchSelection(params) {
      this.isOpenBranchSelection = params;
    },

    async connectToSupplierModal() {
      const modal = await modalController.create({
        component: ConnectedVendor,
        cssClass: 'connect-vender',
        componentProps: {
          close: async () => {
            await modal.dismiss();
          }
        }
      });
      return modal.present();
    },
    goToNotificationPage() {
      this.ionRouter.navigate({
        routerLink: '/b2b/notification'
      });
    },
    openSearchPage() {
      this.$emit('setIsHomePageValue');
      Clevertap.recordEventWithName('User initiates search');
      this.$router.push('/b2b/search-products');
    }
  }
});
</script>
<style lang="scss" scoped>
ion-content::part(scroll) {
  overflow: hidden;
}
.amount-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.fs-15px {
  line-height: 22px;
  color: #9e9e9e;
}

ion-button.count {
  --border-radius: 14px;
}

ion-toolbar {
  --border-width: 0 !important;
}

.header-right {
  text-align: end;
}

.header-right * {
  color: var(--ion-color-primary);
}

.input-search {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding-left: 10px !important;
}

.search-product {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-content: center;
  justify-content: space-between;
}

.close-modal {
  margin-top: 2px;
  margin-right: 4px;
  font-size: 24px;
  color: #9e9e9e;
}

.search {
  margin-left: 2px;
  --background: transparent;
}

.new-search {
  border-radius: 8px;
  height: 36px;
  background: #f5f5f5;
  background-color: #f5f5f5;
  color: black;
}

.btn-icon {
  --padding-start: 0px;
  --padding-end: 0px;
}

.icon-item {
  font-size: 20px;
}

.icon-item:last-child {
  font-size: 20px;
}
@media only screen and (max-width: 280px) {
  .btn-notify {
    width: 22px;
  }
}
</style>
<style lang="scss">
.my-popover {
  --max-width: 120px;
  // --backdrop-opacity: 0;
}
// .my-popover .popover-content,
// .popover-arrow.sc-ion-popover-ios::after {
//   box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
// }
// .my-popover .popover-arrow.sc-ion-popover-ios::after {
//   z-index: 11;
// }
</style>
