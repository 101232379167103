<template>
  <div>
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-grid>
          <ion-row class="align-center" id="row">
            <ion-col size="9" class="d-flex">
              <ion-buttons class="mr-2" @click="closeModal()">
                <ion-icon size="small" :icon="arrowBackOutline"></ion-icon>
              </ion-buttons>
              <ion-label class="fs-4 fw-bold ion-text-uppercase">{{
                $t('connectSupplier.supplier')
              }}</ion-label>
            </ion-col>

            <ion-col size="3" class="header-right d-flex ion-justify-content-around align-center"> </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-header>
    <div class="ion-padding-start ion-padding-end header-box">
      <ion-searchbar
        debounce="500"
        :placeholder="$t('connectToOtherSuppliers')"
        v-model="keyword"
        @ionChange="handleSearchConnectToOtherSuppliers"
      ></ion-searchbar>
    </div>

    <ion-content :scroll-events="true" v-if="isShow">
      <ion-grid class="ion-padding-start ion-padding-end">
        <div
          class="mb-2"
          v-if="
            (customerConnect && customerConnect.length > 0) ||
            (customerUnConnect && customerUnConnect.length > 0)
          "
        >
          <div v-if="!keyword">
            <ion-row class="item" v-for="(tenant, index) in customerConnect" :key="index">
              <ion-col size="7">
                <div class="title">{{ tenant.tenant_name }}</div>
                <div class="payment mt-1">
                  <span>{{ $t('connectSupplier.payment') }}:</span>
                  {{ paymentMethodLabel(tenant.payment_type) }}
                </div>
                <div class="payment mt-1">
                  {{ tenant.payment_type != 2 && tenant.credit_term ? `(${tenant.credit_term}D)` : '' }}
                </div>
              </ion-col>
              <ion-col size="5" class="btn-connect d-flex justify-end">
                <ion-chip color="primary" outline>
                  <ion-icon :icon="shieldCheckmarkOutline"></ion-icon>
                  <ion-label>{{ $t('connectSupplier.connected') }}</ion-label>
                </ion-chip>
              </ion-col>
            </ion-row>
          </div>
          <div v-else>
            <ion-row class="item" v-for="(tenant, index) in customerUnConnect" :key="index">
              <ion-col size="7">
                <div class="title">{{ tenant.name }}</div>
                <div class="payment mt-1">
                  <span>{{ $t('connectSupplier.payment') }}:</span>
                  {{ paymentMethodLabel(tenant.payment_type) }}
                </div>
              </ion-col>
              <ion-col size="5" class="btn-connect d-flex justify-end">
                <ion-chip
                  @click="requestJoin(tenant.id, tenant.name)"
                  :color="tenant.request_join_status == 0 ? 'dark' : 'primary'"
                  :disabled="tenant.request_join_status !== null"
                >
                  <ion-label>{{
                    tenant.request_join_status == null ? $t('connect') : $t('requested')
                  }}</ion-label>
                </ion-chip>
              </ion-col>
            </ion-row>
          </div>
        </div>
        <div
          v-if="(keyword && customerUnConnect.length === 0) || (!keyword && customerConnect.length === 0)"
          class="d-flex justify-center fs-4 fw-500 mt-4"
        >
          {{ $t('connectSupplier.supplier_not_found') }}
        </div>
      </ion-grid>
    </ion-content>
    <ion-button
      class="ion-text-uppercase btn-close"
      size="large"
      expand="block"
      shape="round"
      color="primary"
      @click="closeModal()"
      >{{ $t('close') }}</ion-button
    >
  </div>
  <ion-loading :is-open="loading" cssClass="my-custom-class" :message="`${$t('please_wait')}. . .`">
  </ion-loading>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { alertController } from '@ionic/vue';
import { shieldCheckmarkOutline, arrowBackOutline } from 'ionicons/icons';
import { ACTIONS } from '@/modules/b2b/store/customer/actions';
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('b2b/customer');

import { getPaymentTypes } from '../../../services/libs/helper';
// import { creditTerms } from '../../../services/libs/helper';

export default defineComponent({
  name: 'ConnectedVendor',
  props: {
    close: {
      type: Function
    }
  },
  setup() {
    const isOpenRef = ref(false);
    const setOpen = async (state) => (isOpenRef.value = state);
    return {
      shieldCheckmarkOutline,
      arrowBackOutline,
      isOpenRef,
      setOpen
    };
  },
  data() {
    return {
      isShow: false,
      tenants: [],
      fetchingData: false,
      errorFetchingData: false,
      isConnectedVendor: true, // Indicate tenant list is connected or unconnected vendor
      keyword: '',
      joiningTenantId: null,
      paymentMethods: getPaymentTypes()
    };
  },
  computed: {
    ...mapGetters(['customerConnect', 'loading', 'customerUnConnect'])
  },
  inject: ['$storage'],
  async mounted() {
    this.selectedCompany = await this.$storage.getSelectedCompany();
    this.user = await this.$storage.getUser();
    await this.getData();
    this.isShow = true;
  },
  watch: {
    async customerUnConnect() {
      await this.setOpen(false);
    }
  },
  methods: {
    ...mapActions([
      ACTIONS.GET_UNCONNECTED_TENANT,
      ACTIONS.GET_VIEWS_CUSTOMER_TENANTS,
      ACTIONS.ADD_CUSTOMER_TENANT_JOIN_REQUEST
    ]),
    async getData() {
      await this[ACTIONS.GET_VIEWS_CUSTOMER_TENANTS]({ id: this.selectedCompany.id });
    },
    async handleSearchConnectToOtherSuppliers() {
      this.keyword ? await this.getDataSearch(this.keyword) : await this.getData();
    },
    async getDataSearch(value) {
      const host = window.location.host;
      let params = {
        customerId: this.selectedCompany.id,
        keyword: value,
        isProduction: host.startsWith('dashboard') || host.startsWith('app') ? true : false,
        userCountryId: this.user.country?.id
      };
      await this[ACTIONS.GET_UNCONNECTED_TENANT](params);
    },

    paymentMethodLabel(value) {
      if (!value) value = 0;
      const method = this.paymentMethods.find((obj) => {
        return obj.value == value;
      });

      return method.label;
    },

    async requestJoin(tenantId, tenantName) {
      const alert = await alertController.create({
        cssClass: 'alert-class',
        header: this.$t('vendor_connect'),
        message: `Connect <strong>${
          this.selectedCompany.name ? this.selectedCompany.name : this.selectedCompany.branch
        }</strong> to <strong>${tenantName}</strong> ?`,
        buttons: [
          {
            text: this.$t('no'),
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: this.$t('yes'),
            handler: async () => {
              await this.setOpen(true);
              this[ACTIONS.ADD_CUSTOMER_TENANT_JOIN_REQUEST]({
                customer_id: this.selectedCompany.id,
                tenant_id: tenantId,
                created_by: this.user.id
              });
              this.getDataSearch(this.keyword);
            }
          }
        ]
      });
      await alert.present();

      await alert.onDidDismiss();
    },

    async closeModal() {
      await this.close();
    }
  }
});
</script>

<style lang="scss" scoped>
ion-content {
  height: 75vh;
}

.header-box {
  position: relative;
  .fix-icon {
    position: absolute;
    top: 25px;
    left: 25px;
  }
  .header {
    text-align: center;
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
  }
}

.item {
  margin: 0px 8px;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  .title {
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
  }
  .payment {
    font-size: 12px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.54);
    span {
      font-weight: 700;
    }
  }
  &:last-child {
    border-bottom: 0px;
  }
}
.btn-connect {
  ion-button {
    --border-radius: 20px;
  }
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  ion-button {
    font-weight: 700;
    --border-radius: 20px;
  }
}

.ion-color-disable {
  --ion-color-base: #92949c;
  --ion-color-base-rgb: 245, 247, 250;
  --ion-color-contrast: #000000;
  --ion-color-contrast-rgb: 0, 0, 0;
  --ion-color-shade: #808289;
  --ion-color-tint: #9d9fa6;
}

.ion-color-green {
  --ion-color-base: #72db77;
  --ion-color-base-rgb: 114, 219, 119;
  --ion-color-contrast: #000000;
  --ion-color-contrast-rgb: 0, 0, 0;
  --ion-color-shade: #64c169;
  --ion-color-tint: #80df85;
}
#row {
  height: 50px;
}
.btn-close {
  position: absolute;
  right: 0;
  left: 0;
  bottom: -2px;
  margin: 0;
  border-radius: 0px;
  padding: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 18px;
}
</style>
