<template>
  <ion-content class="d-flex justify-center">
    <ion-button fill="clear" size="small" @click="toggleSortName">{{ labelSortName }}</ion-button>
  </ion-content>
</template>

<script>
import { IonContent, IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { ACTIONS } from '@/modules/b2b/store/product/actions';
import { MUTATIONS } from '@/modules/b2b/store/product/mutations';
import { TYPE_SEARCH } from '@/modules/b2b/constants';

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('b2b/product');

export default defineComponent({
  name: 'Filter',
  components: { IonContent, IonButton },

  computed: {
    ...mapGetters(['search', 'type', 'list']),
    labelSortName() {
      if (this.search.sortType === 'asc') {
        return this.$t('name_za');
      } else {
        return this.$t('name_az');
      }
    }
  },
  props: {
    closePopover: {
      required: true
    }
  },

  methods: {
    ...mapActions([ACTIONS.UPDATE_SEARCH]),
    ...mapMutations([MUTATIONS.RESET_CATEGORY]),
    async toggleSortName() {
      let sortType = this.search.sortType;
      if (sortType === 'asc') {
        sortType = 'desc';
      } else {
        sortType = 'asc';
      }
      this[MUTATIONS.RESET_CATEGORY]();
      this[ACTIONS.UPDATE_SEARCH]({
        search: {
          sortBy: 'name',
          sortType,
          offset: 0,
          mainCategoryId: this.list.category
        },
        type: TYPE_SEARCH.SORT
      });
      await this.closePopover();
    }
  }
});
</script>
<style lang="scss" scoped>
ion-content::part(scroll) {
  overflow: hidden;
}
</style>
